import axios from "@/axios.js";
export default {
  addItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      debugger;
      axios
        .post("vehicles/AddNewVehiclesType", item)
        .then(response => {
          commit("ADD_VehiclesType", Object.assign(item, { ID: response.data.ID }));
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchAllVehiclesTypeList({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .post("/vehicles/GetAllVehiclesTypes")
        .then(response => {
          if (response.status == 200) {
            commit("SET_VehiclesType", response.data);
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchVehiclesTypeByID({ commit }) {
    var Jsonvalue = {};
    Jsonvalue.CountryCode = "All";
    return new Promise((resolve, reject) => {
      axios
        .post("/vehicles/GetVehiclesTypeById", Jsonvalue)
        .then(response => {
          if (response.status == 200) {
            commit("SET_VehiclesType", response.data);
          }
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("/vehicles/UpdateVehiclesType", item)
        .then(response => {
          debugger
          commit("UPDATE_VehiclesType", item);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  DeleteVehiclesType({ commit }, Id) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .put(`/vehicles/DeleteVehiclesType?Id=${Id}`)
        .then(response => {
          debugger;
          commit("REMOVE_VehiclesType", Id);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

//   DeleteVehiclesType({ commit }, item) {
//     debugger;
//   return new Promise((resolve, reject) => {
//     axios
//       .post("vehicles/DeleteVehiclesType" , item)
//       .then(response => {
//         resolve(response);
//         commit("REMOVE_VehiclesType", item);
//       })
//       .catch(error => {
//         reject(error);
//       });
//   });
// },
  UploadImage(context, image) {
    const formData = new FormData();
    // var jsonObject = image.params.Id;
    formData.append("file",image, image.name);
    return new Promise((resolve, reject) => {
      axios
        .post("/vehicles/UploadImage", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.status == 200) {
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

};
